import React from 'react';
import { GoogleMap, useLoadScript,LoadScript, MarkerF } from '@react-google-maps/api';

const containerStyle = {
  width: '130vh',
  height: '75vh',
};


const Map = ({ selectedStore }) => {
  const center = selectedStore ? { lat: selectedStore.coordinates.latitude, lng: selectedStore.coordinates.longitude } : {lat:30,lng:-70};

  const {isLoaded} = useLoadScript({
    googleMapsApiKey:"AIzaSyAh5EOVrO3wFhFw0MQHfh4aAxwuQd4Y3Rk",
})
if(isLoaded) return (<>
  <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={14}>
    <MarkerF position={center} opacity={1} visible={true}/>
  </GoogleMap>
</>
);
if(!isLoaded) return(<>
<p>WAIT</p>
</>)
};

export default Map;
import React, { useState } from 'react';

const ProjectCard = ({ title, summary, githubLink, websiteLink, imageUrl }) => {
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  return (
    <div className="card has-background-light">
      <div className="card-content">
        <figure className="image is-4by3">
          <img src={imageUrl} alt='image' className="image" />
        </figure>
        <p className="title is-4 mt-3">{title}</p>
        <div className="content">
          {showDetails ? summary : summary.slice(0, 16) + '...'}
          {summary.length > 13 && (
            <span className="icon has-text-info ml-2" onClick={toggleDetails}>
              <i className={`fas ${showDetails ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
            </span>
          )}
        </div>
        {showDetails && summary.length > 16 && (
          <div className="mt-2">
            {/* {summary} */}
          </div>
        )}
        <div className="buttons mt-2 is-flex is-align-items-center">
          <a className="button is-info is-outlined is-rounded mr-2" onClick={toggleDetails}>
            {showDetails ? 'Less' : 'More'}
          </a>
          <a
            href={githubLink}
            className="button is-dark is-outlined is-rounded mr-2"
            target="_blank"
            rel="noopener noreferrer"
          >
            Github
          </a>
        </div>
        {websiteLink !=0 && (
          <div className="mt-2">
            <a
              href={websiteLink}
              className="button is-info is-outlined is-rounded"
              target="_blank"
              rel="noopener noreferrer"
            >
              Website
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectCard;

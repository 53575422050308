import './App.css';
import { Route, Routes } from "react-router-dom";
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import About from './pages/About';
import Location from './pages/Location';
import Contact from './pages/Contact';
import Projects from './pages/Projects';

function App() {

  return (
    <div className="App">
      <Navbar />
      <div className="container hero is-fullheight" >
        <Routes>
          <Route path="/" element={<About />} />
          <Route path="/location" element={<Location />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/projects" element={<Projects />} />
        </Routes>
        <Footer />
      </div>
    </div>
  );
}


export default App;

import { Link } from 'react-router-dom';
export default function Footer() {
    return (
        <div className="has-background-info">
            <footer className="footer mt-auto  has-text-centered">
                <>
                    <span>Copyright @2023  </span>
                </>
                <Link to="/about">grnsingh</Link>
            </footer>
        </div>
    );
}
import { Link } from 'react-router-dom';
export default function Navbar() {


    return (
        <div className="has-background-white">
        <div className="container is-fullhd">
            <nav className="navbar " role="navigation" aria-label="main navigation">

                <div className="navbar-menu is-active ">
                    <div className="navbar-start">


                    </div>

                    <div className="navbar-end">
                        <div className="navbar-item">
                            <div className="buttons">

                                <Link to="/" className="navbar-item py-2 px-2 is-size-4">
                                    About
                                </Link>

    

                                <Link to="/projects" className="navbar-item py-2 is-size-4">
                                    Projects
                                </Link>
                               



                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="navbar-menu is-active">
                        <div className="navbar-item "> */}
                            {/* <div className="buttons"> */}
                            {/* <h1 className=" py-2 is-size-3">Wish - Telecom</h1>
                                <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="true">
                                    <span aria-hidden="true"></span>
                                    <span aria-hidden="true"></span>
                                    <span aria-hidden="true"></span>
                                </a> */}


                                {/* <Link to="/About" className="navbar-item py-2 px-2 is-size-4">
                                    About
                                </Link>

                                <Link to="/Location" className="navbar-item py-2 px-2 is-size-4">
                                    Locations
                                </Link>

                                <Link to="/Contact" className="navbar-item py-2 px-2  is-size-4">
                                    Contact
                                </Link>

                                <Link to="/Careers" className="navbar-item py-2 px-2 mr-3 is-size-4">
                                    Careers
                                </Link> */}

                            {/* </div> */}
                        {/* </div>
                    </div> */}
                    
             
            </nav>
        </div>
        </div>
    );
}

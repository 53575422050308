import { useFormik } from "formik";
import {useState} from"react";

// Contact.js

import React from 'react';

const Contact = () => {
  return (
    <div className="netflix-style-contact">
      <header className="netflix-header">
        <h1 className="title is-1 has-text-white">Your Name</h1>
      </header>
      <main>
        <section className="section contact-info">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-6">
                <h2 className="title is-2">Contact Me</h2>
                <ul className="contact-list">
                  <li>
                    <strong>Email:</strong> <a href="mailto:your.email@example.com">your.email@example.com</a>
                  </li>
                  <li>
                    <strong>LinkedIn:</strong> <a href="https://www.linkedin.com/in/yourusername" target="_blank" rel="noopener noreferrer">LinkedIn Profile</a>
                  </li>
                  {/* Add other contact information as needed */}
                </ul>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Contact;

import locations from '../wishTelecomLocations/locations.json';
import photo from '../images/westwindsStore.jpg'
import Map from '../components/Map'
import React, {useState} from 'react'


  
export default function Location() {

  const [selectedStore, setSelectedStore] = useState(null);
    return (
        <div className="Location py-1" > 
            <div className="columns">
                <div className="column is-one-quarter slider">
                {
                    locations.stores.map((store, index, props )=>
                    <>
                    <div className="card py-1">
                    <div className="card-image">
                      <figure className="image is-128by128">
                        <img src={photo} alt="Placeholder image"></img>
                      </figure>
                    </div>
                    <div className="card-content">
                        <div className="media-content">
                          <p className="subtitle is-6">{store.address}</p>
                          <p className="subtitle is-6">contact us at {store.phone}</p>
                          <button onClick={() => setSelectedStore(store)}>Select</button>
                        </div>
                    </div>
                  </div>
                  </>
                  
                    )
                }
                    
                 </div>
                 <div className="column is-three-quarters">
                    <Map selectedStore={selectedStore}/>
                  </div>   
            </div>
        </div>
    );
}


import zooScheduler from '../images/javaProjss.png';
import spamClassifier from '../images/spamClassifier2.png';
import suduko from '../images/suduko.png';
import shell from '../images/shell.jpeg';
import lt from '../images/lotion.png';
//  to change
import mdt from '../images/mdt.jpeg';
import dt from '../images/dataStructures.png';
import React, { useEffect } from 'react';

import AOS from 'aos';
import ProjectCard from '../components/ProjectCard'
import 'aos/dist/aos.css';

const projectsData = [
  {
    title: 'Data Structures Lib',
    summary: " A comprehensive Data Structures Library in Java, utilizing Eclipse IDE and Maven build automation. The library encompasses essential data structures like LinkedLists, Stack, Queue, Binary Search Trees, AVL trees, and Graphs. To ensure seamless integration, I crafted a user-friendly .jar plugin, simplifying the incorporation of these data structures into Java projects. This initiative significantly enhances code reusability and maintainability, providing developers with a powerful toolset for efficient software development.",
    githubLink: 'https://github.com/grnsingh/dataStructuresLib',
    websiteLink: 0,
    imageUrl: dt,
  },
 
  {
    title: 'Spam Classifier',
    summary: "Highly accurate and efficient system developed to automatically detect and filter out spam messages from legitimate communication channels, such as emails or text messages. Utilizing advanced machine learning algorithms, the program meticulously analyzes message content and patterns to distinguish between spam and non-spam content. With an accuracy rate of 98 percent, this classifier ensures precise identification of spam, thereby enhancing user experience, and saving valuable time",
    githubLink: 'https://github.com/grnsingh/spam-classifier',
    websiteLink: '0',
    imageUrl: spamClassifier,
  },
  {
    title: 'Suduko Vision',
    summary: "Implemented techniques to locate and extract the Sudoku board from image, ensuring accurate digit recognition. Fine-tuned OCR parameters and optimized the image processing pipeline for precise digit recognition. Utilized Python, OpenCV, and EasyOCR for effective solving",
    githubLink: 'https://github.com/grnsingh/mlSudukoSolver',
    websiteLink: '0',
    imageUrl: suduko,
  },
  {
    title: 'Linux Shell',
    summary: "Developed a Linux shell that offers command-line interaction and efficient multitasking capabilities. In this project, I successfully implemented features such as pipelining, allowing users to connect multiple commands and create powerful data processing pipelines. Additionally, the shell manages concurrent background processes effortlessly, enhancing the user experience. I ensured accurate parsing of user input, enabling swift execution of external programs. Navigating the Linux environment became more intuitive with my shell, as it automatically resolved command paths, ensuring quick access to executables. My Linux shell project combines functionality and simplicity, providing users with a versatile tool for efficient command execution and multitasking in the Linux ecosystem.",
    githubLink: 'https://github.com/grnsingh/myShell',
    websiteLink: '0',
    imageUrl: shell,
  },
  {
    title: 'Museum Database',
    summary: "Developed a robust Relational Database tailored specifically for an art museum's needs. This custom database efficiently manages vast collections of artworks, artists, exhibitions, and historical data. It provides a seamless platform for museum administrators to organize, categorize, and retrieve information effortlessly. With features like relational tables, data normalization, and optimized querying, the database ensures the museum's valuable art assets are cataloged, tracked, and presented",
    githubLink: 'https://github.com/Maan-Khedr-ENSF-300/museum-project-slytherin',
    websiteLink: '0',
    imageUrl: mdt,
  },

  {
    title: 'Lotion Notes',
    summary: 'Lotion is a streamlined online notes app, empowering users to jot down ideas, tasks, and reminders with ease. Its intuitive interface ensures quick note-taking, helping users stay organized and productive on any device. Stay focused and capture thoughts on the go with Lotion.',
    githubLink: 'https://github.com/grnsingh/lotionNotes',
    websiteLink: '0',
    imageUrl: lt,
  },
  
  {
    title: 'ZOO Scheduler App',
    summary: "The Zoo Volunteer Scheduling App in Java streamlines volunteer management, allowing efficient allocation of tasks and shifts. With intuitive features, it simplifies volunteer sign-ups, tracks schedules, and ensures optimal staffing, enhancing the zoo's operational efficiency and visitor experience",
    githubLink: 'tobeAdded',
    websiteLink: '0',
    imageUrl:zooScheduler,
  },
  
];

const Projects = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div>
      <div className="columns is-multiline mt-4">
        {projectsData.map((project, index) => (
          <div
            className="column is-3 my-2"
            key={index}
            data-aos="fade-up" // Choose your desired animation
            data-aos-duration="1000" // Animation duration in milliseconds
          >
            <ProjectCard {...project} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projects;

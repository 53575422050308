
import React from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import image1 from '../images/photo2My.jpg';

AOS.init();

const About = () => {
  return (
    <section className="hero is-fullheight">
      <div className="hero-body is-family-code">
        <div className="container has-text-centered">
          <h1 className="title is-0 is-family-sans-serif">Gurnoor Singh</h1>
          <h2 className="subtitle is-5 ">Software Engineering Student</h2>
          <p className="subtitle is-6 ">
          I am a dedicated software engineering student with a passion for problem-solving and innovation. 
          Throughout my academic journey, I have honed my skills in coding, algorithm design, and software development. 
          I am committed to continuous learning and staying up-to-date with emerging technologies. My goal is to leverage my technical 
          knowledge and 
          creative mindset to contribute to exciting projects and collaborate with dynamic teams in the world of software development.
          </p>

          <div className="buttons is-centered">
            <a
              href="https://github.com/grnsingh"
              className="button is-dark is-outlined is-rounded mx-2"
              target="_blank"
              rel="noopener noreferrer"
            >
              GitHub
            </a>
            <a
              href="https://www.linkedin.com/in/gurnoor--singh"
              className="button is-info is-outlined is-rounded mx-2"
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn
            </a>
            <a
              href="mailto:grn.singh1@gmail.com"
              className="button is-danger is-outlined is-rounded mx-2"
            >
              Gmail
            </a>
          </div>

          <figure
           
            data-aos="fade-in"
            data-aos-duration="1000"
          >
            <img 
              src={image1}
              alt="Gurnoor Singh"
              className="is-rounded is-128x128"
              style={{ width: '390px', height: '390px' }}

            />
          </figure>

          <div className="mt-5" data-aos="fade-up" data-aos-duration="1000">
            
          <br/>
  <div className="mb-4">
    <h3 className="title is-1 mt-">Education</h3>
    <p className="subtitle is-6 mt-5">
    A software engineering student, thriving in the dynamic environment of university. On a consistent journey of academic excellence. Beyond the lecture halls, I am a passionate advocate for holistic growth. Currently serving as the VP Events at ZOO, our university's Electrical and Software Student Society, I actively curate impactful events and foster a sense of belonging within our community.


          </p>
    
    <div className="mb-3">
      <h4 className="subtitle is-4">Bachelor's of Science in Software Engineering</h4>
      <p className="subtitle is-5">
        University of Calgary <br />
        Graduation Year: 2025<br/>
        GPA : 3.76
      </p>
    </div>
    <br/>
    <br/>
    <h4 className="subtitle is-4">High School Diploma</h4>
      <p className="subtitle is-5">
        Ryan International School <br />
        Graduation Year: 2020<br/>
        GPA : 4.0 
      </p>
  </div>
</div>
<br/>
<br/>

<div className="mt-6" data-aos="fade-up" data-aos-duration="1000">
            <div className="block has-text-centered">
              <h3 className="title is-1">Skills</h3>
              <br/>
              
              <p className="subtitle is-6  ">
              As a software engineering student, I possess a diverse skill set that combines technical expertise with problem-solving acumen. Proficient in various programming languages, including Java, Python, and JavaScript, I excel in algorithm design and implementation. My strong grasp of data structures enables me to create efficient and scalable software solutions.

In addition to my technical prowess, I have hands-on experience with software development tools and frameworks such as Git, Docker, and React. I am adept at utilizing modern development methodologies like Agile and Scrum, ensuring projects are completed on time and within scope.

          </p>
              <div className="tags is-centered mt-3">
                <span className="tag is-info subtitle is-6">C++</span>
                <span className="tag is-info subtitle is-6">Agile Development</span>
                <span className="tag is-info subtitle is-6">Python</span>
                <span className="tag is-info subtitle is-6">C</span>
                <span className="tag is-info subtitle is-6">AWS</span>
                <span className="tag is-info subtitle is-6">Terraform</span>
                <span className="tag is-info subtitle is-6">RUST</span>
              </div>
              <div className="tags is-centered">
                <span className="tag is-info subtitle is-6">React</span>
                <span className="tag is-info subtitle is-6">Terraform</span>
                <span className="tag is-info subtitle is-6">CSS</span>
                <span className="tag is-info subtitle is-6">DynamoDB</span>
                <span className="tag is-info subtitle is-6">JavaScript</span>
                <span className="tag is-info subtitle is-6">Html</span>
              </div>
              <div className="tags is-centered ">
                <span className="tag is-info subtitle is-6">Java</span>
                <span className="tag is-info subtitle is-6">Docker</span>
                <span className="tag is-info subtitle is-6">ML</span>
                <span className="tag is-info subtitle is-6">C#</span>
                <span className="tag is-info subtitle is-6">Jenkins</span>
                <span className="tag is-info subtitle is-6">AngularJS</span>
                <span className="tag is-info subtitle is-6">Software LifeCycles</span>
                <span className="tag is-info subtitle is-6">JUnit</span>
              </div>
             
            </div>
          </div>
        </div>
        </div>
      
    </section>
  );
};

export default About;
